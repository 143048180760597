<app-header-three></app-header-three>

<!-- Home slider start-->
<section class="p-0">
  <app-slider 
    [sliders]="sliders" 
    [textClass]="'text-center'"
    [category]="'perfume'"> 
  </app-slider>
</section>
<!-- Home slider End -->

<!-- Services -->
<section class="banner-padding absolute-banner pb-0">
  <div class="container absolute-bg">
    <div class="service p-0 ">
      <app-services></app-services>
    </div>
  </div>
</section>
<!-- Services end -->

<!-- collection banner start-->
<section class="banner-padding banner-furniture">
  <div class="container-fluid">
      <div class="row partition3">
          <div class="col-md-4" *ngFor="let collection of collections">
              <a [routerLink]="['/shop/collection/left/sidebar']" 
                 [queryParams]="{ category: collection.category}">
                  <div class="collection-banner p-right text-end">
                      <img [src]="collection.image" alt="collection" class="img-fluid">
                      <div class="contain-banner banner-3">
                          <div>
                              <h4>{{collection.save}}</h4>
                              <h2 class="text-white">{{collection.title}}</h2>
                          </div>
                      </div>
                  </div>
              </a>
          </div>
      </div>
  </div>
</section>
<!-- collection banner End -->
<!-- product section -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="title4">
                    <h2 class="title-inner4">trending products</h2>
                    <div class="line">
                        <span></span>
                    </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 offset-xl-3">
                      <div class="product-para">
                          <p class="text-center">Explore our trending collections, where luxury meets olfactory excellence. 
                            Indulge your senses, elevate your style, and leave an unforgettable impression with our iconic perfumes.</p>
                      </div>
                  </div>
              </div>
                <owl-carousel-o class="product-5 product-m no-arrow" [options]="ProductSliderConfig">
                  <ng-container *ngFor="let product of products | slice:0:8">
                    <ng-template carouselSlide>
                      <div class="product-box product-wrap">
                          <app-product-box-two 
                            [product]="product"
                            [currency]="productService?.Currency">    
                          </app-product-box-two>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- product section end -->

<!-- Parallax banner -->
<section class="p-0">
    <div class="full-banner parallax-banner15 parallax text-start p-left"
        [ngStyle]="{'background-image': 'url(assets/images/legend/legend-mix.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="banner-contain">
                        <h2>2024</h2>
                        <h3>Fragrance Trend</h3>
                        <h4>special offer</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Parallax banner end -->

<!-- product-box slider -->
<section>
  <div class="full-box">
      <div class="container">
          <div class="title4">
              <h2 class="title-inner4">special products</h2>
              <div class="line">
                  <span></span>
              </div>
          </div>
          <div class="row">
              <div class="col-md-4">
                  <div class="theme-card center-align">
                      <div class="offer-slider">
                          <div class="sec-1">
                              <div class="product-box2" *ngFor="let product of products | slice:0:2">
                                <app-product-box-vertical 
                                  [product]="product"
                                  [currency]="productService?.Currency">    
                                </app-product-box-vertical>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-4 center-slider">
                  <div>
                      <div class="offer-slider">
                          <div>
                              <div class="product-box product-wrap" *ngFor="let product of products | slice:1:2">
                                <app-product-box-two 
                                  [product]="product"
                                  [currency]="productService?.Currency">    
                                </app-product-box-two>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="theme-card center-align">
                      <div class="offer-slider">
                          <div class="sec-1">
                              <div class="product-box2" *ngFor="let product of products | slice:2:4">
                                <app-product-box-vertical 
                                  [product]="product"
                                  [currency]="productService?.Currency">    
                                </app-product-box-vertical>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- product-box slider end -->

<!-- blog section start-->
<!-- <section class="blog section-b-space pt-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blogs"></app-blog>
      </div>
    </div>
  </div>
</section> -->
<!-- blog section end -->

<!-- Cart Open To Right Side-->
<app-cart-variation [direction]="'right'"></app-cart-variation> 
<app-footer-one></app-footer-one>
